.proposed-merges-grid .wj-cells {
  //  font-size: 12px;
}

.proposed-merges-grid .wj-cell {
  overflow: hidden;
  min-height: 28px;
  padding: 2px 6px 0 6px;
}

.proposed-merges-grid .wj-cell.wj-alt {
  background: transparent !important;
}

.proposed-merges-grid .wj-cells .wj-cell.wj-state-selected {
  background: #97fff778 !important;
  color: #000 !important;
}

.proposed-merges-grid .wj-cells .wj-cell.wj-state-multi-selected {
  background: #97fff778 !important;
  color: #000 !important;
}

.proposed-merges-grid .wj-input-group-btn {
  display: none !important;
}

.proposed-merges-grid .wj-header {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  //  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.proposed-merges-grid .wj-node.wj-state-selected {
  color: inherit;
  background: #97fff778 !important;
}
