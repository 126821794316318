.tab-item {
  height: calc(100vh - #{nb-theme(tab-item-indent-top)} - 2px);
  max-height: calc(100vh - #{nb-theme(tab-item-indent-top)} - 2px);
  margin-top: nb-theme(tab-item-margin-top);
  margin-bottom: nb-theme(tab-item-margin-bottom);
  overflow: nb-theme(tab-item-overflow);
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
