/* You can add global styles to this file, and also import other style files */

$color_celeste: #ccc;
$color_fair_pink: #fee;
$color_white: #fff;
$color_black: #000;
$color_steel_blue: #4682b4;
$color_gallery: #eee;
$color_storm_dust: #666;
$color_grey: #808080;
$color_boston_blue: #3182bd;
$color_blue: #00f;

//fonts
$font_sans_serif: sans-serif;

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//original selectors
//.dc-chart path.dc-symbol, g.dc-legend-item.fadeout
%extend_1 {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
}

.dc-chart {
    svg {
        align-self: center;
    }
    rect {
        &.bar {
            stroke: none;
            cursor: pointer;
            &:hover,
            &:focus {
                fill-opacity: 0.5;
            }
        }
        &.deselected {
            stroke: none;
            fill: $color_celeste;
        }
    }
    .pie-slice {
        fill: $color_white;
        font-size: 12px;
        cursor: pointer;
        &.external {
            fill: $color_black;
        }
        &:focus {
            fill-opacity: 0.8;
        }
        :hover &.highlight {
            fill-opacity: 0.8;
        }
    }
    .pie-path {
        fill: none;
        stroke-width: 2px;
        stroke: $color_black;
        opacity: 0.4;
    }
    .selected {
        path,
        circle {
            stroke-width: 3;
            stroke: $color_celeste;
            fill-opacity: 1;
        }
    }
    .deselected {
        path,
        circle {
            stroke: none;
            fill-opacity: 0.5;
            fill: $color_celeste;
        }
    }
    .axis {
        path,
        line {
            fill: none;
            stroke: $color_black;
            shape-rendering: crispEdges;
        }
        text {
            font: 10px $font_sans_serif;
        }
    }
    .grid-line,
    .axis .grid-line,
    .grid-line line,
    .axis .grid-line line {
        fill: none;
        stroke: $color_celeste;
        shape-rendering: crispEdges;
    }
    .brush {
        rect {
            &.selection {
                fill: $color_steel_blue;
                fill-opacity: 0.125;
            }
        }
        .custom-brush-handle {
            fill: $color_gallery;
            stroke: $color_storm_dust;
            cursor: ew-resize;
        }
    }
    path {
        &.line {
            fill: none;
            stroke-width: 1.5px;
        }
        &.area {
            fill-opacity: 0.3;
            stroke: none;
        }
        &.highlight {
            stroke-width: 3;
            fill-opacity: 1;
            stroke-opacity: 1;
        }
        &.dc-symbol {
            @extend %extend_1;
        }
    }
    g {
        &.state {
            cursor: pointer;
            :hover,
            :focus {
                fill-opacity: 0.8;
            }
            path {
                stroke: $color_white;
            }
        }
        &.deselected {
            path {
                fill: $color_grey;
            }
            text {
                display: none;
            }
        }
        &.row {
            rect {
                fill-opacity: 0.8;
                cursor: pointer;
                &:hover,
                &:focus {
                    fill-opacity: 0.6;
                }
            }
            text {
                fill: $color_white;
                font-size: 12px;
                cursor: pointer;
            }
        }
        &.dc-tooltip path {
            fill: none;
            stroke: $color_grey;
            stroke-opacity: 0.8;
        }
        &.county path {
            stroke: $color_white;
            fill: none;
        }
        &.debug rect {
            fill: $color_blue;
            fill-opacity: 0.2;
        }
        &.axis text {
            @include no-select;
            pointer-events: none;
        }
    }
    .node {
        font-size: 0.7em;
        cursor: pointer;
        :hover,
        :focus {
            fill-opacity: 0.8;
        }
    }
    .bubble {
        stroke: none;
        fill-opacity: 0.6;
    }
    .highlight {
        fill-opacity: 1;
        stroke-opacity: 1;
    }
    .fadeout {
        fill-opacity: 0.2;
        stroke-opacity: 0.2;
    }
    .box {
        text {
            font: 10px $font_sans_serif;
            @include no-select;
            pointer-events: none;
        }
        line {
            fill: $color_white;
        }
        rect,
        line,
        circle {
            stroke: $color_black;
            stroke-width: 1.5px;
        }
        .center {
            stroke-dasharray: 3, 3;
        }
        .data {
            stroke: none;
            stroke-width: 0px;
        }
        .outlier {
            fill: none;
            stroke: $color_celeste;
        }
        .outlierBold {
            fill: red;
            stroke: none;
        }
        &.deselected {
            opacity: 0.5;
            .box {
                fill: $color_celeste;
            }
        }
    }
    .symbol {
        stroke: none;
    }
    .heatmap {
        .box-group.deselected rect {
            stroke: none;
            fill-opacity: 0.5;
            fill: $color_celeste;
        }
        g.axis text {
            pointer-events: all;
            cursor: pointer;
        }
    }
    .empty-chart .pie-slice {
        cursor: default;
        path {
            fill: $color_fair_pink;
            cursor: default;
        }
    }
}

.dc-data-count {
    float: right;
    margin-top: 15px;
    margin-right: 15px;
    .filter-count,
    .total-count {
        color: $color_boston_blue;
        font-weight: bold;
    }
}

.dc-legend {
    font-size: 11px;
    .dc-legend-item {
        cursor: pointer;
    }
    g.dc-legend-item.fadeout {
        @extend %extend_1;
    }
    g.dc-legend-item.selected {
        fill: blue;
    }
}

.dc-hard .number-display {
    float: none;
}

div.dc-html-legend {
    overflow-y: auto;
    overflow-x: hidden;
    height: inherit;
    float: right;
    padding-right: 2px;
    .dc-legend-item-horizontal {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
        &.selected {
            background-color: rgb(49, 130, 189);
            color: white;
        }
    }
    .dc-legend-item-vertical {
        display: block;
        margin-top: 5px;
        padding-top: 1px;
        padding-bottom: 1px;
        cursor: pointer;
        &.selected {
            background-color: rgb(49, 130, 189);
            color: white;
        }
    }
    .dc-legend-item-color {
        display: table-cell;
        width: 12px;
        height: 12px;
    }
    .dc-legend-item-label {
        line-height: 12px;
        display: table-cell;
        vertical-align: middle;
        padding-left: 3px;
        padding-right: 3px;
        font-size: 0.75em;
    }
}