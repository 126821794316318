.list-answer-items {
  padding-left: 0rem !important;
}

.answer-type {
  word-break: break-all;
}

.answer-type label {
  padding: 1px !important;
}
