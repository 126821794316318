.wj-cells>.wj-row {
    &>.wj-cell {
        background-color: #fff;
    }
}

.wj-cell {
    color: #444444 !important;

    &.wj-state-selected,
    &.wj-state-multi-selected {
        background: #97fff778 !important;
        color: #444444 !important;
    }

    &.wj-header {
        background: #eee;
        color: #444444 !important;
        font-weight: 700;
    }
}

.grid-with-detail {
    .wj-cells:nth-of-type(1)>.wj-row {
        &>.wj-cell {
            &.cell-with-icon {
                padding: 0 !important;
            }

            &>div {
                .icon-container {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    padding-top: 1px;
                    padding-bottom: 2px;
                    padding-left: 3px;
                    padding-right: 0.3rem;
                    background-color: #eee;

                    i {
                        margin-top: 0.2rem;
                        font-size: 1.2em;
                    }
                }

                .text-padding {
                    padding-left: 6px;
                }

                .icon-margin {
                    margin-left: 1.5rem;
                }

                .cell-padding {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }
        }
    }
}