@use './assets/css/themes.scss' as *;
@use '@nebular/theme/styles/globals' as *;
@import 'quill/dist/quill.snow.css';
@import 'eva-icons/style/eva-icons.css';

@import "./assets/css/custom-webkit-scroll.scss";
@import "./assets/css/object-list.scss";
@import "./assets/css/context-menu";
@import "./assets/css/jump-menu";
@import "./assets/css/decision-answer";
@import "./assets/css/go-js-diagram";
@import "./assets/css/list-item";
@import "./assets/css/form-controls";
@import "./assets/css/disable-text-selection";
@import "./assets/css/icon";
@import "./assets/css/tab";
@import "./assets/css/print.scss";
@import "./assets/css/login.scss";
@import './assets/css/proposed-merged-grid.scss';
@import "./assets/css/dc-charts.scss";
@import "./assets/css/wijmo-flex-grid.scss";

@include nb-install() {
  @include nb-theme-global();
}

.user-permission-grid .wj-cells {
  height: fit-content !important;
}

.user-permission-grid .wj-cell {
  border-right: none;
  overflow: hidden;
  height: fit-content !important;
  min-height: 28px;
}

.user-permission-grid-search .wj-input-group-btn {
  display: none !important;
}

.user-permission-grid .wj-header {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.addGroupInUser .search-control {
  width: 100% !important;
}

.user-permission-tree .wj-node {
  .remove-item {
    font-size: 1.2em;
    position: absolute;
    top: 0.15em;
    margin-left: 0.5em;
  }
}

.related-object-grid .wj-cells {
  height: fit-content !important;
}

.related-object-grid .wj-cell {
  overflow: hidden;
  height: fit-content !important;
  min-height: 28px;
  padding: 2px 6px 0 6px;
}

.related-object-grid .wj-cell.wj-alt {
  background: transparent !important;
}

.related-object-grid .wj-cells .wj-cell.wj-state-selected {
  background: #97fff778 !important;
  color: #000 !important;
}

.related-object-grid .wj-cells .wj-cell.wj-state-multi-selected {
  background: #97fff778 !important;
  color: #000 !important;
}

.related-object-grid .wj-input-group-btn {
  display: none !important;
}

.related-object-grid .wj-header {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.related-object-grid .wj-node.wj-state-selected {
  color: inherit;
  background: #97fff778 !important;
}

.nb-theme-default nb-select.appearance-outline.size-medium .select-button {
  padding: 0.4375rem 1.125rem;
}

.nb-theme-default nb-select .select-button {
  min-width: 10rem;
}

.associate-project-view-select .select-button {
  min-width: 10rem;
  max-width: 10rem;
}

.branch-compare-tree {
  .wj-node {
    position: relative;
  }
}

.forms-container,
.select-target-object-properties-view {
  nb-card {
    box-shadow: none;

    .tab-item {
      height: auto;
      max-height: none;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
    }
  }
}

.forms-container {
  .status-icon {
    // margin-top: 0.5em;
    margin-left: 0.5em;
  }

  .status-update {
    color: orange;
  }
}

.form-control-container {
  display: inline-flex;
  width: 100%;

  .conflict-checkbox {
    margin-left: -0.9rem;
    margin-right: 0.5rem;
  }

  core-edit-multiple-lines-control {
    width: 100%;
    height: 100%;
  }
}

.nb-theme-default nb-select.appearance-outline .select-button:hover,
.nb-theme-default nb-select.appearance-outline .select-button:focus {
  border: 2px solid #085d6ad1;
}

.nb-theme-default nb-option.selected,
.nb-theme-default nb-option:hover {
  color: #085d6ad1;
}

.nb-theme-default nb-select.appearance-outline .select-button[disabled],
.nb-theme-default nb-select.appearance-outline .select-button {
  border-color: #ccc;
}

.nb-theme-default nb-option-list.size-medium nb-option,
.nb-theme-default nb-select.size-medium .select-button {
  font-weight: unset !important;
}

.form-edit-object {
  display: block;
  width: 100%;
  font-size: 1rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #0d86ff;
  border-radius: .25rem;
  transition: border-color .15s
}

.nb-theme-default [nbButton].appearance-outline.status-info {
  border-color: #16a2b8;
  color: #16a2b8;
  margin-left: 10px;
  border-radius: 0.5rem;
  text-transform: none;
  font-size: 15px;
  font-weight: 400;
}

.nb-theme-default [nbButton].appearance-outline.status-info:hover {
  border-color: #057a8b;
  color: #057a8b;
  font-weight: 500;
}

.approver-select nb-select .select-button {
  min-width: 16rem;
  background-color: #fff !important;
}

.review-note .ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-radius: 30px;
}

b,
.wj-treeview .wj-node .has-text {
  background: #E1F9F6 !important;
}

.branch-tree.wj-treeview {
  width: auto;
  overflow: unset;
}

::-webkit-scrollbar:horizontal {
  min-height: 10px !important;
}

.wj-popup {
  min-width: 205px;
  max-width: 500px;
  min-height: 10em;
  max-height: 300px;
  resize: both;
  overflow: auto;
}

.wj-popup>div {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.node-tool-tip {
  background-color: #000;
  color: #fff;
  border: none;
}

.search-result-popup {
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;

    [class*="col-"] {
      padding-left: 5px !important;
      padding-right: 5px !important;
      padding-bottom: 5px !important;
    }
  }

  .loaded-results {
    padding: 5px !important;
  }

  .get-more-results {
    padding: 0 !important;
  }
}

.custom-wijmo-tooltip {
  max-width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nb-theme-default nb-toggle .toggle {
  height: 1.1rem;
  width: 2.5rem;
  border: 1px solid #16a2b8;
}

.nb-theme-default nb-toggle .toggle-switcher {
  width: 1rem;
  height: 1rem;
}

.nb-theme-default nb-toggle .toggle:hover,
.nb-theme-default nb-toggle .toggle.checked,
.nb-theme-default nb-toggle .toggle:visited,
.nb-theme-default nb-toggle .toggle:active,
.nb-theme-default nb-toggle .toggle:focus {
  background-color: #16a2b8 !important;
  border-color: #16a2b8 !important;
}

.nb-theme-default nb-toggle .text {
  white-space: nowrap;
}

.nb-theme-default nb-toggle .native-input:focus+.toggle {
  border-color: #16a2b8 !important;
}

.nb-theme-default nb-toggle .native-input:active+.toggle,
.nb-theme-default nb-toggle .native-input:hover+.toggle {
  background-color: #16a2b8 !important;
  border-color: #16a2b8 !important;
}

.primary-color {
  color: nb-theme(primary-color) !important;
}

.darken-primary-color {
  color: nb-theme(darken-primary-color) !important;
}

.enhanced-combo {
  .wj-listbox-item.category {
    padding-top: 4px;
    padding-left: 5px;
    font-weight: bold;
    background: #f0f0f0;
    color: #808080;
    position: sticky;
    top: 0;
    opacity: 1;
  }

  .wj-listbox-item.load-more {
    background: #fff;
    font-size: 0.8rem;
    color: #007bff;
    text-align: end;
    cursor: pointer;
  }

  .wj-listbox-item.load-more:hover {
    background: #fff !important;
    transition: none !important;
  }

  .h-0 {
    height: 0 !important;
    overflow: hidden !important;
  }

  .wj-listbox-item.sub-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // .wj-listbox-item.sub-dropdown::after {
  //   content: "";
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   margin-top: 3px;
  //   margin-left: 10px;
  //   border-width: 6px 0px 6px 8px;
  //   border-color: transparent transparent transparent #474747;

  //   &.opened {
  //     border-width: 8px 6px 0 6px;
  //     border-color: #474747 transparent transparent transparent;
  //   }
  // }
}

.pointer {
  cursor: pointer !important;
}

.attribute-chip-context-menu {
  z-index: 1150;

  a {
    text-decoration: none;
  }
}

nb-option.normal.selected {
  background-color: #17a2b8 !important;
  color: #474747 !important;

  &:hover {
    color: #fff !important;
  }
}

.text-with-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.wj-tooltip {
  background-color: rgb(227 246 244);
}

.dc-html-legend-container {
  .dc-html-legend {
    display: flex;
    flex-flow: column wrap;
    height: 6em;
    width: 100%;
    overflow: auto;
    gap: 5px;
  }
}

.wj-treeview .wj-node.wj-state-selected {
  background-color: #d8eef0;
}

.cell-control-multiselect {
  .wj-content {
    display: flex;
    flex-direction: column;
  }

  &.disable-selected-state {
    .wj-state-selected {
      background: #fff !important;
      color: #000 !important;
    }
  }
}

.hierarchical-menu {
  .wj-listbox-item {
    min-width: 5em;

    &.group-border {
      border-bottom: 2px solid #b5b5b5;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.flex-1 {
  flex: 1 !important;
}

.message-popup {
  resize: none;
  min-height: auto;
}

.multi-select-dropdown {

  .wj-listbox,
  .wj-multiselectlistbox {
    display: block;
  }

  .wj-state-selected {
    background: unset;
    color: unset;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}