@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}

@media print {
  footer {
    margin-top: 30px;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  .content-block, p {
    page-break-inside: avoid;
  }

  html, body {
    width: 210mm;
    height: 297mm;
  }
}

@page {
  @bottom-center {
      content: element(pageFooter);
  }
}

#pageFooter{
  position: running(pageFooter);
}

.footer-space {
  height: 2cm;
}
